import axios from "axios";

export default (token = false) => {
  return axios.create({
    // baseURL: "https://api.intrepidbroadband.hidayahsmart.solutions/users/v2/",
    baseURL: "https://api.fibermapdemo.hidayahsmart.solutions/users/v2/",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
  });
};
